// Here you can add other styles
// Here you can add other styles
table.fixHead > thead {
  background-color: rgb(213, 216, 213);
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.fixHead > tbody {
  background-color: rgb(255, 255, 255);
  display: block;
  overflow: auto;
  table-layout: fixed;
  max-height: 350px;
}

table.fixHead > tbody > tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  width: 100%;
}
